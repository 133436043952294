import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/icomoon.css';
import './App.css';

import '../src/assets/css/dark-theme.css'
import '../src/assets/css/light-theme.css'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/Pages/Authentication/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Integration from './components/Pages/Integration/Integration';
import Help from './components/Pages/Help/Help';
import MyAccount from './components/Pages/Profile/MyAccount';
import Privacy from './components/Pages/Profile/Privacy';
import Upgrade from './components/Pages/Upgrade/Upgrade';
import ResetPassword from './components/Pages/Authentication/ResetPassword';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { removeAlert } from './Redux/Actions/AlertActions';
import { loadUser } from './Redux/Actions/AuthActions';
import { onFetchFont } from './Redux/Actions/CommonActions';
import ForgotPasword from './components/Pages/Authentication/ForgotPassword';
import MasterLogin from './components/Pages/Authentication/MasterLogin';
import AccountManagement from './components/Pages/AccountManagement/AccountManagement';
import Alert from './components/CommonComponents/Alert';
import PrivateRoute from './components/CommonComponents/PrivateRoute';
import NewSetting from './components/Pages/CloneStudio/StudioSetting/NewSetting';
import HelpPost from './components/Pages/Help/HelpPost';
import SupportArticle from './components/Pages/Help/SupportArticle';
import Settings from './components/Pages/Settings/outreach/Settings';
import CreateClone from './components/Pages/Clones/CreateClone';
import CloneStudio from './components/Pages/CloneStudio/CloneStudio';
import CloneNav from './components/Pages/CloneStudio/CloneNav';
import ScrollTop from './components/CommonComponents/ScrollTop';
import Chat from './components/Pages/Chat/Chat';
import PaymentWindow from './components/CommonComponents/PaymentWindow';
function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  const fetchUser = () => {
    dispatch(loadUser())
  }

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])


  const fetchFonts = () => {
    dispatch(onFetchFont(false, true))
  }


  useEffect(() => {
    fetchUser()
    fetchFonts()
  }, [])

  return (
    <div className="App">
      <Alert />

      <Router>
        <ScrollTop />

        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/forgot-password" element={<ForgotPasword />} />
          <Route exact path="/master-login" element={<MasterLogin />} />

          <Route exact path="/dashboard" element={<PrivateRoute> <Dashboard /></PrivateRoute>} />
          <Route exact path="/integration" element={<PrivateRoute><Integration /></PrivateRoute>} />
          <Route exact path="/help" element={<PrivateRoute><Help /></PrivateRoute>} />
          <Route exact path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
          <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
          <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
          <Route exact path="/upgrade" element={<PrivateRoute><Upgrade /></PrivateRoute>} />
          <Route exact path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
          <Route exact path="/support-article" element={<PrivateRoute><SupportArticle /></PrivateRoute>} />
          <Route exact path="/help-post" element={<PrivateRoute><HelpPost /></PrivateRoute>} />
          <Route exact path="/new-setting" element={<PrivateRoute><NewSetting /></PrivateRoute>} />

          <Route exact path="/create-clone" element={<PrivateRoute><CreateClone /></PrivateRoute>} />
          <Route exact path="/clone-studio" element={<PrivateRoute><CloneStudio /></PrivateRoute>} />
          <Route exact path="/clone-nav" element={<PrivateRoute><CloneNav /></PrivateRoute>} />
          <Route path="/chat/:username" element={<Chat />} />
          <Route path="/payment" element={<PaymentWindow />} />

        </Routes>
      </Router>

    </div>
  );
}

export default App;
