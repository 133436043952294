import React, { useEffect, useState } from 'react';
import { BsStars } from "react-icons/bs";
import { FaUser, FaUserGroup } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import CloneImage from './CommonSection/CloneImage';
import queryString from "query-string"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { onFetchTimeMeter } from '../../../Redux/Actions/CloneActions';

const CloneHome = ({ clone, userData, messageData }) => {

    const cloneDOB = useSelector((state) => state.clone.data.created)
    const dispatch = useDispatch()
    const location = useLocation()
    const { cId } = queryString.parse(location.search)
    const [currentData, setCurrentData] = useState({
        userCount: userData.length,
        messageCount: messageData.length
    });
    const [loader, setLoader] = useState({
        load: false
    })
    const [percentage, setPercentage] = useState([])
    const [selectData, setSelectData] = useState({
        userFilter: "",
        messageFilter: ""
    });
    const fetchTimeClone = () => {
        const data = {
            cloneId: clone.data.id
        }
        setLoader({

            load: true
        })
        dispatch(onFetchTimeMeter(data, setPercentage, loader, setLoader))
    }
    const calculateDates = (filter) => {
        let startDate;
        const today = new Date();
        const endDate = new Date();
        const dates = [];

        switch (filter) {
            case "last7Days":
                startDate = new Date(today.setDate(today.getDate() - 7));
                break;
            case "last15Days":
                startDate = new Date(today.setDate(today.getDate() - 15));
                break;
            case "last30Days":
                startDate = new Date(today.setMonth(today.getMonth() - 1));
                break;
            default:
                startDate = new Date(cloneDOB.split(" ")[0]);
                break;
        }

        if (startDate <= endDate) {
            while (startDate <= endDate) {
                dates.push(startDate.toISOString().split('T')[0]);
                startDate.setDate(startDate.getDate() + 1); // Move to the next day
            }
        }
        return dates;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectData({ ...selectData, [name]: value })
        const datesArray = calculateDates(value);

        if (name === "messageFilter") {
            const messageCount = messageData.filter(message => datesArray.includes(message.created.split(" ")[0])).length;
            setCurrentData({ ...currentData, messageCount });
        } else {
            const userCount = userData.filter(user => datesArray.includes(user.created.split(" ")[0])).length;
            setCurrentData({ ...currentData, userCount });
        }

    }

    useEffect(() => {
        setCurrentData({ ...selectData, userCount: userData.length, messageCount: messageData.length })
    }, [messageData, userData])
    useEffect(() => {
        fetchTimeClone()
    }, [])
    return (
        <>
            <div className='studio-right' style={{ width: "100%" }}>
                <div className='pageTitle flex'>
                    <h2>Home</h2>
                    <CloneImage
                        image={clone.data.image}
                        radiness={percentage}
                        loader={loader}
                    />
                </div>

                <div className='welcome-studio'>
                    <div className='welcome-studio-left'>
                        <div className='studio-left-icon'><BsStars /></div>
                        <p>Welcome to Clone Studio
                        </p>

                    </div>
                    <Link to={`/new-setting?cId=${cId}`} className='theme-btn' ><span>Settings</span></Link>
                </div>

                <div className='clone-summary'>
                    <h5 className='summary-title'>Summary</h5>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='clone-summary-single'>
                                <div className='clone-summary-title'>
                                    <h5>Users</h5>
                                    <div className='summary-period'>
                                        <select
                                            name="userFilter"
                                            value={selectData.userFilter}
                                            onChange={handleChange}
                                        >
                                            <option value="">Past </option>
                                            <option value="last7Days">7 Days</option>
                                            <option value="last15Days">15 Days</option>
                                            <option value="last30Days">30 Days</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h2>{currentData.userCount}</h2> <h5>Users</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='clone-summary-single'>
                                <div className='clone-summary-title'>
                                    <h5>Messages</h5>
                                    <div className='summary-period'>
                                        <select
                                            name='messageFilter'
                                            value={selectData.messageFilter}
                                            onChange={handleChange}
                                        >
                                            <option value="">Past </option>
                                            <option value="last7Days">7 Days</option>
                                            <option value="last15Days">15 Days</option>
                                            <option value="last30Days">30 Days</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h2>{currentData.messageCount}</h2> <h5>Messages</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='clone-summary-single'>
                                <div className='clone-summary-title'>
                                    <h5>Revenues</h5>
                                    <div className='summary-period'>
                                        <select name="" id="">
                                            <option value="">Past </option>
                                            <option value="last7Days">7 Days</option>
                                            <option value="last15Days">15 Days</option>
                                            <option value="last30Days">30 Days</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h2><span>$</span>0</h2> <h5>USD</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='clone-summary for-action'>
                    <h5 className='summary-title'>Quick Actions</h5>
                    <div className='row m-0'>
                        <Link style={{ color: "#000" }} to={`/new-setting?cId=${cId}`} className='col-lg-4 p-0'>
                            <div className='clone-summary-single first'>
                                <div className='clone-summary-title'>
                                    <div className='studio-left-icon'><FaUser /></div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h6><strong>Update Clone Profile</strong> <br /> <small>Make changes to your Clone's profile to add additional details.</small></h6>
                                </div>
                            </div>
                        </Link>
                        <Link style={{ color: "#000" }} to={`/clone-nav?cId=${cId}&type=audience`} className='col-lg-4 p-0'>
                            <div className='clone-summary-single mid'>
                                <div className='clone-summary-title'>
                                    <div className='studio-left-icon'><FaUserGroup /></div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h6><strong>Explore Audience</strong> <br /> <small>Examine the recently added Audience tab in Clone Studio.</small></h6>
                                </div>
                            </div>
                        </Link>
                        <Link style={{ color: "#000" }} to={`/clone-nav?cId=${cId}&type=train`} className='col-lg-4 p-0'>
                            <div className='clone-summary-single last'>
                                <div className='clone-summary-title'>
                                    <div className='studio-left-icon'><FaFileAlt /></div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h6><strong>Upload Content</strong> <br /> <small>Upload files, movies, webpages, and more to help your clone Train.</small></h6>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )

}

export default CloneHome;