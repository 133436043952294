import React, { useEffect } from 'react';

const PaymentWindow = () => {
    useEffect(() => {
        window.close();
    });

    return <></>;
};

export default PaymentWindow;