import React, { useEffect, useState } from 'react';
import { Nav, Tab, Button } from 'react-bootstrap';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import TitleBar from '../../CommonComponents/TitleBar';

import { MdEdit } from "react-icons/md";
import { BsChatDotsFill } from "react-icons/bs";
import { fetchData } from '../../../Redux/Actions/CommonActions';
import CloneCards from './CloneCards';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { onFetchTimeMeter } from '../../../Redux/Actions/CloneActions';


const CloneStudio = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [cloneData, setCloneData] = useState([])

    const fetchClones = () => {
        dispatch(fetchData("fetch-all-clone", {}, setCloneData, loader, setLoader))
    }

    useEffect(() => {
        fetchClones()
    }, [])

    return (
        <>
            <Header />
            <TitleBar title="Clone Studio" />
            <div className="site-wrap">
                <div className="site-container">
                    <div className='container'>
                        <div className='clone-card-wrap'>
                            <div className='heading-block pb-3'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h2>My Clones</h2>
                                    {+auth.user.isClientAccount === 1 ? null :
                                        <Link to={'/create-clone'} className='theme-btn'><span>Create Clone</span></Link>}
                                </div>
                                {cloneData.length > 0 ?
                                    <p className='mt-3'> Manage all your clones and its properties. </p>
                                    : null}
                            </div>
                            <div className='row'>
                                {cloneData.length > 0 ?
                                    cloneData.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <CloneCards
                                                    curElem={curElem}
                                                    cloneData={cloneData}
                                                    setCloneData={setCloneData}
                                                />
                                            </React.Fragment>
                                        )
                                    })

                                    :
                                    <div className='text-center mt-5'>
                                        {loader.fetch ? <i className='fa fa-spin fa-spinner fetch-loader' /> :

                                            <p>You do not have any clone yet. Click on "Create Clone" to create a new clone.</p>

                                        }
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Footer />

        </>
    )

}

export default CloneStudio;