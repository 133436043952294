import React, { useEffect, useState } from 'react'
import dummyProfile from '../../../../assets/images/dummyImage.png'

const CloneImage = (props) => {
    let arr = Array(30).fill("")
    const [activeBar, setActiveBar] = useState(-1)

    useEffect(() => {
        let total = (30 * props.radiness) / 100
        setActiveBar(total)
    }, [props.radiness])
    return (
        <div className='clone-radiness alt mt-0'>
            <div className='radiness-graph mt-0'>
                <div className='clone-card-profile-img alt'><img src={props.image || dummyProfile} alt="" /></div>
                <div className='radiness-graph-left'>
                    {arr.length > 0 ?
                        arr.map((bar, index) => {
                            return (
                                <span className={index < +activeBar ? "active" : ""} key={index}></span>
                            )
                        })

                        : ""}

                </div>
                {props?.loader?.load ? <i className='fa fa-spin fa-spinner fetch-loader' /> :
                    <>
                        <div className='radiness-stat'>{props.radiness ? props.radiness : '0'}%</div>
                    </>
                }
            </div>
        </div>
    )
}

export default CloneImage