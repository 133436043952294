import React, { useState } from 'react'
import tweet from "../../../../../assets/images/tweet.png"
import tiktok from "../../../../../assets/images/tiktok.png"
import instagram from "../../../../../assets/images/instagram.png"
import linkedin from "../../../../../assets/images/linkedin.png"
import single_file from "../../../../../assets/images/single_file.png"
import { AiOutlineClose } from 'react-icons/ai'
import Swal from 'sweetalert2'
import { themeColor } from '../../../../../Global/Global'
import { onUploadDocs } from '../../../../../Redux/Actions/CloneActions'
import { useDispatch } from 'react-redux'
import { onUploadMedia } from '../../../../../Redux/Actions/CommonActions'
import { useSelector } from 'react-redux'
import { FaSpinner } from 'react-icons/fa6'

const SocialsModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        upload: false
    })
    const [fileName, setFileName] = useState("");

    const [percent, setPercent] = useState(0)
    const [socialRadio, setSocialRadio] = useState({
        socialType: "twitter"
    });
    const handleChange = (e) => {
        const { name, value, check } = e.target
        setSocialRadio({ ...socialRadio, [name]: value })
    };

    const uploadFile = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            if (file.type.toLowerCase() === "application/x-zip-compressed") {
                if (file.size < 300000000) {
                    const formData = new FormData()
                    setFileName(file.name);
                    formData.append("file", file)
                    formData.append("upload_type", "zip")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, false, loader, setLoader, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 300MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    return (
        <>
            <div className="content_modal">
                <div className='d-flex justify-content-between align-items-center mt-3' >
                    <h2 className=''>Upload Socials</h2>
                    <AiOutlineClose className='close_icon' fontSize={20} onClick={props.handleClose} />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="me-2 mt-3">
                                <label htmlFor='x'>
                                    <div className="website_pop">
                                        <div className='website_upload' style={{ position: "relative" }}>
                                            <img src={tweet} alt="" />
                                            <div className="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="socialType"
                                                    id="x"
                                                    value="twitter"
                                                    checked={socialRadio.socialType === 'twitter'}
                                                    onChange={handleChange}
                                                />

                                            </div>
                                        </div>
                                        <div>
                                            <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px" }}>Upload your X profile</p>
                                        </div>
                                    </div>
                                </label>
                            </div>

                            <div className="me-2 mt-3">
                                <label htmlFor='instagram'>
                                    <div className="website_pop">
                                        <div className='website_upload' style={{ position: "relative" }}>
                                            <img src={instagram} alt="" />
                                            <div className="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="socialType"
                                                    id="instagram"
                                                    value="instagram"
                                                    checked={socialRadio.socialType === 'instagram'}
                                                    onChange={handleChange}
                                                />

                                            </div>
                                        </div>
                                        <div>
                                            <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px" }}>Upload your Instagram</p>
                                        </div>
                                    </div>
                                </label>

                            </div>
                            <div className=" mt-3">
                                <label htmlFor='linkedIn'>
                                    <div className="website_pop">
                                        <div className='website_upload' style={{ position: "relative" }}>
                                            <img src={linkedin} alt="" />
                                            <div className="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="socialType"
                                                    id="linkedIn"
                                                    value="linkedIn"
                                                    checked={socialRadio.socialType === 'linkedIn'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px" }}>Upload your LinkedIn</p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="col-12 mt-4">
                            <ul style={{ listStyleType: "number", paddingLeft: "20px" }}>
                                <li style={{ fontSize: "13px", fontWeight: "600" }}> <a
                                    href={socialRadio.socialType === "twitter" ? "https://twitter.com/settings/download_your_data?lang=en" : socialRadio.socialType === "instagram" ? "https://accountscenter.instagram.com/info_and_permissions/dyi/" : "https://www.linkedin.com/mypreferences/d/download-my-data"}
                                    target='_blank' style={{ color: '#ff6a27' }}> Click here to request your {socialRadio.socialType === "twitter" ? "Twitter/X" : socialRadio.socialType === "instagram" ? "Instagram" : "LinkedIn"} data.</a></li>
                                <li className='mt-3' style={{ fontSize: "13px", fontWeight: "500", color: "#71717a" }}>After you receive your data export from Step 1, upload the ZIP file below.</li>
                            </ul>
                            <p className='mt-2 mb-3 ps-1'>
                                <strong style={{ color: "#71717a", fontSize: "13px" }}>You will only have to do this once as we can auto sync new tweets every week afterwards.</strong>
                            </p>
                        </div>
                        <div className="col-12">
                            <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />

                        </div>

                        <div className="col-12">
                            <div className='upload_wrap mt-2'>
                                <div className='upload_content_wrap'>
                                    {
                                        loader.upload ? (
                                            <FaSpinner className="spin" size={25} />
                                        ) : (
                                            fileName ? (
                                                <h5>{fileName}</h5>
                                            ) : (
                                                <img src={single_file} alt="" style={{ height: "98px" }} />
                                            )
                                        )
                                    }

                                    {/* <img src={single_file} alt="" style={{ height: "98px" }} /> */}
                                    {/* {loader.upload ? null : */}
                                    <input
                                        type="file"
                                        onChange={uploadFile}
                                    />
                                    <h3 >Upload Files</h3>
                                    <p className="text-p3 text-center"><span className="text-brand">Click to browse</span> or drag and drop</p>
                                    {loader.upload ?
                                        <div className="progress mt-3">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, background: `linear-gradient(to left, ${themeColor}, ${themeColor} 100%)` }} aria-valuenow={`${percent}`}
                                                aria-valuemin="0" aria-valuemax="100">{percent}%

                                            </div>
                                        </div>
                                        : ""}

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

export default SocialsModal